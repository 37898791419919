import React from 'react'
//Types
import { SectionBaseProps, SectionTestimonial } from '../../../types/sections'
//Components
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Box } from '../../../atoms'
import { TestimonialFull } from '../../testimonials'

export interface TestimonialProps extends SectionBaseProps {
  data: SectionTestimonial
}

const Testimonial: React.FC<TestimonialProps> = ({ data }) => {
  const { id, sectionId, backgroundColor, testimonial } = data

  const backgroundColorHex = backgroundColor ? backgroundColor.hex : 'white'

  return (
    <Box as={'section'} id={sectionId || id} className={'p-section'} my={[80, 80, 120]}>
      <Grid>
        <Row center={'xs'} middle={'xs'}>
          <Col xs={12}>{testimonial && <TestimonialFull {...testimonial} backgroundColor={backgroundColorHex} />}</Col>
        </Row>
      </Grid>
    </Box>
  )
}
export default Testimonial
